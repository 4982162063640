/* .particles {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.particles div {
    position: absolute;
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    line-height: 40px;
    color: #FFF;
    font-family: 'Roboto', sans-serif;
    text-transform: uppercase;
    animation: neon-1 .1s ease-in-out infinite alternate;
} */

.particles canvas {
    position: absolute;
    width: 100%;
    height: 75%;
    /* background-image: url('background.jpg'); */
    background-position: center center;
    background-size: cover;
    z-index: 0;
    left: 0;
    top: 0;
}

@media (max-width: 860px) {
    .p-timeline-event-opposite{
        display: none;
    } 
}
